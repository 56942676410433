import React from 'react'
import styled, { themeGet } from '@style'

const ThirdSection = styled.section`
  background: hsl(0, 0%, 0%);
  position: relative;
  overflow: hidden;
  margin-top: -2px;

  h2 {
    color: inherit;
    font-weight: ${themeGet('fontWeights.medium')};
    max-width: 468px;
    margin: 0 auto;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* stylelint-disable */
    background: linear-gradient(
      210deg,
      hsla(0, 0%, 0%, 0.8),
      hsla(0, 0%, 0%, 0.9)
    );
    /* stylelint-enable */
  }
`

const BaseSVG = props => (
  <svg viewBox="0 0 1000 114" fill="none" {...props}>
    <path
      width="100%"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1000 0H0V48.4792C162.604 21.1994 329.644 7 500 7C670.356 7 837.396 21.1994 1000 48.4792V0Z"
      fill="#fff"
    />
  </svg>
)

const ThirdSeparator = styled(BaseSVG)`
  width: 100%;
  position: relative;
  display: block;
  z-index: 20;
`

export { ThirdSection, ThirdSeparator }
