/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import { graphql, navigate } from 'gatsby'

import { Layout } from '@components/Layout'
import { Flex, Box } from '@components/Grid'
import { SEO } from '@components/SEO'
import { Blob } from '@components/Blob'
import { NewsletterSection } from '@components/NewsletterSection'
import { Hero } from '@components/Hero'
import { H2, H3, H4, StrongDarkBackgroundWrapper } from '@components/Heading'
import { Text } from '@components/Text'
import { Button } from '@components/Button'
import {
  HeroSection,
  FirstSection,
  FirstSeparator,
  SecondSection,
  SecondSeparator,
  ThirdSection,
  ThirdSeparator,
} from '@components/PageComponents/About'
import { useScrollToElement } from '@hooks/useScrollToElement'

const AboutPage = ({ data: { content }, location }) => {
  // For scroll-to-button
  const targetRef = React.useRef(null)
  const { scrollToTarget } = useScrollToElement()

  return (
    <Layout>
      <SEO
        metadata={{
          title: content.seo.seoTitle,
          description: content.seo.seoDescription,
          pathname: location.pathname,
        }}
      />
      <HeroSection>
        <Hero>
          <Hero.Heading content={content._rawHero.heading} />
          <Hero.Text content={content._rawHero.text} />
          <Hero.Button onClick={() => scrollToTarget(targetRef.current)}>
            {content._rawHero.cta.label}
            {content._rawHero.cta.showArrow && <Hero.Button.Arrow />}
          </Hero.Button>
        </Hero>
      </HeroSection>
      <FirstSection>
        <FirstSeparator />
        <Flex width="100%" justifyContent="center" px={5} py={7}>
          <H3
            content={content._rawFirstSectionClaim}
            textAlign="center"
            color="blue.700"
          />
        </Flex>
        <div ref={targetRef}>
          <Box
            position="relative"
            zIndex={10}
            mt={[7, 8, 9]}
            width="100%"
            flexDirection="column"
            px={[5, 10]}
            py={[5, 6]}
            maxWidth="700px"
            m="0 auto"
          >
            <H2 content={content._rawFirstSectionHeading} maxWidth="600px" />
            <Text
              content={content._rawFirstSectionText}
              maxWidth="500px"
              mt={[5, 6]}
              pl={[0, 6]}
              mb={6}
            />
            <Blob
              display="block"
              position="absolute"
              width="70%"
              top="50%"
              left="50%"
              zIndex="-1"
              css={{
                transform: 'translate3d(-50%, -50%, 0)',
                background:
                  'linear-gradient(210deg, hsla(255, 100%, 100%, 0.1), hsla(255, 100%, 100%, 1))',
              }}
              pb="70%"
              borderRadius="42% 58% 44% 56% / 66% 45% 55% 34%"
            />
          </Box>
        </div>
      </FirstSection>
      <SecondSection>
        <SecondSeparator />
        <Box
          display="grid"
          width="100%"
          gridTemplateColumns={['1fr', '3fr 2fr', '4fr 3fr']}
          gridTemplateAreas={["'image' 'text'", "'text image'"]}
        >
          <Flex
            gridArea="image"
            display="flex"
            justifyContent={['center', null, 'flex-start']}
            alignItems="center"
            p={[5]}
          >
            <Box display="block" width={['50%', '100%', '80%']}>
              <Blob
                width="100%"
                pb="100%"
                fluid={content.secondSectionImage.asset.fluid}
                borderRadius="42% 58% 44% 56% / 66% 45% 55% 34%"
              />
            </Box>
          </Flex>
          <Flex
            gridArea="text"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            p={[5, 6, 7]}
          >
            <Box>
              <H2 content={content._rawSecondSectionHeading} />
              <Box pl={[0, 6]} mt={[5, 6]} pb={[5]}>
                <Text
                  content={content._rawSecondSectionText}
                  maxWidth="500px"
                />
                <Button onClick={() => navigate('/people')}>
                  {content.secondSectionCTA.label}
                  {content.secondSectionCTA.showArrow && <Button.Arrow />}
                </Button>
              </Box>
            </Box>
          </Flex>
        </Box>
      </SecondSection>
      <ThirdSection>
        <ThirdSeparator />
        <Img
          fluid={content.thirdSectionImage.asset.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
          }}
        />
        <StrongDarkBackgroundWrapper>
          <Flex
            position="relative"
            width="100%"
            flexDirection="column"
            px={5}
            maxWidth="900px"
            m="0 auto"
            pt={[7, 9]}
            color="white"
            zIndex="40"
          >
            <H2 content={content._rawThirdSectionHeading} textAlign="center" />

            <Box mt={[5, 6]}>
              <Text
                content={content._rawThirdSectionText}
                color="white"
                maxWidth="500px"
                margin="0 auto"
                mb={[4, 6]}
              />
              <H4
                content={content._rawThirdSectionClaim}
                color="white"
                textAlign="center"
                mb={6}
              />

              <Button
                margin="0 auto"
                mb={[7, 9]}
                onClick={() => navigate('/expertise')}
              >
                {content.thirdSectionCTA.label}
                {content.thirdSectionCTA.showArrow && <Button.Arrow />}
              </Button>
            </Box>
          </Flex>
        </StrongDarkBackgroundWrapper>
      </ThirdSection>
      <NewsletterSection />
    </Layout>
  )
}

export const query = graphql`
  query AboutPage {
    content: sanityAboutPageSingleton {
      seo {
        seoDescription
        seoTitle
      }
      _rawHero
      _rawFirstSectionClaim
      _rawFirstSectionHeading
      _rawFirstSectionText(resolveReferences: { maxDepth: 10 })
      _rawSecondSectionHeading
      _rawSecondSectionText(resolveReferences: { maxDepth: 10 })
      secondSectionImage {
        asset {
          fluid(maxWidth: 700) {
            ...GatsbySanityImageFluid
          }
        }
      }
      secondSectionCTA {
        label
        showArrow
      }
      _rawThirdSectionHeading
      _rawThirdSectionText(resolveReferences: { maxDepth: 10 })
      _rawThirdSectionClaim
      thirdSectionCTA {
        label
        showArrow
      }
      thirdSectionImage {
        asset {
          fluid(maxWidth: 1400) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`

export default AboutPage
