import React from 'react'
import styled from '@style'

const SecondSection = styled.section`
  background: hsl(0, 0%, 100%);
  position: relative;
  overflow: hidden;
  margin-top: -2px;
`

const BaseSVG = props => (
  <svg viewBox="0 0 1400 151" fill="none" {...props}>
    <path
      width="100%"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1400 0H0V50.3728C238.777 29.9243 483.029 28.4795 728.729 47.2598C954.182 64.7688 1180.76 99.2493 1400 150.262V0Z"
      fill="hsl(216, 50%, 89%)"
    />
  </svg>
)

const SecondSeparator = styled(BaseSVG)`
  width: 100%;
`

export { SecondSection, SecondSeparator }
