import React from 'react'
import styled, { themeGet } from '@style'

const FirstSection = styled.section`
  overflow: hidden;
  /* stylelint-disable */
  background: linear-gradient(
    180deg,
    ${themeGet('colors.blue.100')},
    ${themeGet('colors.blue.200')}
  );
  /* stylelint-enable */
`

const BaseSVG = props => (
  <svg viewBox="0 0 1400 177" fill="none" {...props}>
    <path
      width="100%"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1400 0H0V176.681C459.223 16.4124 978.569 -24.084 1400 130.491V0Z"
      fill="#fff"
    />
  </svg>
)

const FirstSeparator = styled(BaseSVG)`
  width: 100%;
`

export { FirstSection, FirstSeparator }
